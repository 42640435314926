import { create } from 'zustand';

export const LOGIN_STEPS = {
  LOGIN: 'login',
  VERIFY_EMAIL: 'verifyEmail',
  FORGOT_PASSWORD: 'forgotPassword',
  FORGOT_PASSWORD_COMPLETE: 'forgotPasswordComplete',
};

const useLoginModalStore = create((set) => ({
  isOpen: false,
  step: LOGIN_STEPS.LOGIN,
  canClose: true,
  handleModal: (open, canClose = true) => set(() => ({ isOpen: open, canClose })),
  toggleModal: () => set((prev) => ({ step: LOGIN_STEPS.LOGIN, isOpen: !prev.isOpen })),
  setStep: (step) => set(() => ({ step })),
}));

export default useLoginModalStore;
