const OPEN_ACCOUNT = '/open-account';
export const ROUTES = {
  LOGIN: '/login',
  OPEN_ACCOUNT: OPEN_ACCOUNT,
  OPEN_ACCOUNT_SURVEY: OPEN_ACCOUNT + '/survey',
  OPEN_ACCOUNT_FINANCIAL: OPEN_ACCOUNT + '/financial',
  VERIFY_EMAIL: '/email-verification',
  CONTACT_US: '/contact',
  PRIVACY_POLICY: '/privacy_policy',
  FORM_CRS: '/form_crs',
  DISCLAIMERS: '/warranties&disclaimers',
  FINALISE: '/finalise',
  SSO: '/sso',
  IB_LOGIN: '/ib-login',
  USER_ACCOUNT: '/account',
};
