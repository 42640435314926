import React from 'react';

const Keyboard = ({ className }) => {
  return (
    <svg className={className} viewBox='0 0 24 24'>
      <path
        fill='currentColor'
        d='M3 21q-.825 0-1.413-.588T1 19V6q0-.825.588-1.413T3 4h18q.825 0 1.413.588T23 6v13q0 .825-.588 1.413T21 21H3Zm0-2h18V6H3v13Zm5-2h8v-1H8v1Zm-3-3h2v-2H5v2Zm4 0h2v-2H9v2Zm4 0h2v-2h-2v2Zm4 0h2v-2h-2v2ZM5 10h2V8H5v2Zm4 0h2V8H9v2Zm4 0h2V8h-2v2Zm4 0h2V8h-2v2ZM3 19V6v13Z'
      />
    </svg>
  );
};

export default Keyboard;
