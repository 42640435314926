const base = process.env.REACT_APP_API_URL || '';

export const countriesEndpoint = base + '/lookups/COUNTRY';
export const surveySendAnswers = base + '/survey/';

export const loginEndpoint = base + '/auth/login';
export const logoutEndpoint = base + '/auth/logout';
export const emailCodeEndpoint = base + '/auth/email/verify';
export const sessionEndpoint = base + '/auth/getSession';
export const registerUserEndpoint = base + '/auth/register';
export const sendCodeEndpoint = base + '/auth/email/sendCode';
export const forgotPasswordEndpoint = base + '/auth/forgot';
export const forgotPasswordCompleteEndpoint = base + '/auth/forgot_complete';

export const lookupEndpoint = base + '/lookups/';
export const createAccountEndpoint = base + '/ibkr/createAccount';
export const contactSubmit = base + '/contact/submit';
export const ssoCreateEndpoint = base + '/ibkr/ssocreate';
export const postSignature = base + '/sign_documents';
export const getDocsEndpoint = base + '/get_documents';
export const finaliseAccountEndpoint = base + '/ibkr/finalise';
