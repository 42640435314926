import React from 'react';

const SimpleArrow = ({ className }) => {
  return (
    <svg viewBox='0 0 21 21' fill='none' className={className}>
      <path
        d='M4.59375 7.54688L10.5 13.4531L16.4062 7.54688'
        stroke='black'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default SimpleArrow;
