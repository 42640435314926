import React from 'react';

const Print = ({ className }) => {
  return (
    <svg viewBox='0 0 48 48' fill='none' className={className}>
      <path
        d='M36 34.5H38.25C39.2436 34.497 40.1958 34.101 40.8984 33.3984C41.601 32.6958 41.997 31.7436 42 30.75V15.75C41.997 14.7564 41.601 13.8042 40.8984 13.1016C40.1958 12.399 39.2436 12.003 38.25 12H9.75C8.75635 12.003 7.80425 12.399 7.10163 13.1016C6.39901 13.8042 6.00297 14.7564 6 15.75V30.75C6.00297 31.7436 6.39901 32.6958 7.10163 33.3984C7.80425 34.101 8.75635 34.497 9.75 34.5H12'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinejoin='round'
      />
      <path
        d='M33.72 22.5H14.28C13.0208 22.5 12 23.5208 12 24.78V39.72C12 40.9792 13.0208 42 14.28 42H33.72C34.9792 42 36 40.9792 36 39.72V24.78C36 23.5208 34.9792 22.5 33.72 22.5Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinejoin='round'
      />
      <path
        d='M36 12V9.75C35.997 8.75635 35.601 7.80425 34.8984 7.10163C34.1958 6.39901 33.2436 6.00297 32.25 6H15.75C14.7564 6.00297 13.8042 6.39901 13.1016 7.10163C12.399 7.80425 12.003 8.75635 12 9.75V12'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinejoin='round'
      />
      <path
        d='M36.75 19.5C37.9926 19.5 39 18.4926 39 17.25C39 16.0074 37.9926 15 36.75 15C35.5074 15 34.5 16.0074 34.5 17.25C34.5 18.4926 35.5074 19.5 36.75 19.5Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default Print;
