import classnames from 'classnames';
import React, { useEffect } from 'react';

import useUserStore from '../../stores/userStore';
import Footer from '../Footer';
import Header from '../Header';

import { Helmet } from 'react-helmet';

const Layout = ({ children, modifierClass, hideContact, title, ...rest }) => {
  const fetchUserSession = useUserStore((state) => state.fetchUserSession);

  useEffect(() => {
    fetchUserSession();
  }, [fetchUserSession]);

  return (
    <div className={classnames('layout', { [`layout--${modifierClass}`]: modifierClass })}>
      <Helmet>
        <title>{title ? `${title} | ` : ''}Richmond Capital</title>
      </Helmet>
      <Header {...rest} login='Client Login' />
      <main>{children}</main>
      <Footer
        hideContact={hideContact}
        contactUs='Contact us'
        privacyPolicy='Privacy Policy'
        formCRS='Form CRS'
        disclaimers='Warranties & Disclaimers'
      />
    </div>
  );
};

export default Layout;
