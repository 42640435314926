'use client';
import classnames from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { Logo } from '../Icons';

const Footer = ({ modifierClass, privacyPolicy, contactUs, formCRS }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <footer className={classnames('footer', { [`footer--${modifierClass}`]: modifierClass })}>
      <div className='h-wrapper'>
        <Link to='/'>
          <Logo className='footer__logo' />
        </Link>
        <div className='footer__buttons'>
          <a className='footer__button' href={ROUTES.CONTACT_US}>
            {contactUs}
          </a>
          <a className='footer__button' href={ROUTES.PRIVACY_POLICY}>
            {privacyPolicy}
          </a>
          <a className='footer__button' href={ROUTES.FORM_CRS}>
            {formCRS}
          </a>
        </div>
      </div>
      <div className='footer__disclaimer'>
        <div className='footer__disclaimer-content'>
          <p>© 2024 Richmond Capital Advisors LLC. All rights reserved.</p>
          <p>
            Richmond Capital is a brand of Richmond Capital Advisors LLC (RCA), an SEC registered
            investment adviser. Brokerage, custody and clearing services are offered by Interactive
            Brokers LLC, an SEC registered broker-dealer and member FINRA/SIPC. Please consider your
            objectives before investing. A diversified portfolio does not ensure a profit or protect
            against a loss. Past performance does not guarantee future results. Neither this website
            nor any of its contents shall constitute an offer, solicitation, or advice to buy or
            sell securities in any jurisdictions where RCA. is not registered. Any information
            provided prior to opening an account is on the basis that it will not constitute
            investment advice and that RCA is not a fiduciary to any person by reason of providing
            such information.{' '}
            {!isExpanded && (
              <button className='footer__disclaimer-button' onClick={() => setIsExpanded(true)}>
                Read More.
              </button>
            )}
            {isExpanded && (
              <>
                There are no warranties implied. RCA may only transact business in those states in
                which it is registered, or qualifies for an exemption or exclusion from registration
                requirements. RCA&apos;s web site is limited to the dissemination of general
                information pertaining to its advisory services, together with access to additional
                investment-related information, publications, and links. Accordingly, the
                publication of RCA&apos;s web site on the Internet should not be construed by any
                consumer and/or prospective client as RCA&apos;s solicitation to effect, or attempt
                to effect transactions in securities, or the rendering of personalized investment
                advice for compensation, over the Internet. Any subsequent, direct communication by
                RCA with a prospective client shall be conducted by a representative that is either
                registered or qualifies for an exemption or exclusion from registration in the state
                where the prospective client resides. For information pertaining to the registration
                status of RCA, please contact the state securities regulators for those states in
                which RCA maintains a registration filing. A copy of RCA&apos;s current written
                disclosure statement discussing RCA&apos;s business operations, services, and fees
                is available at the SEC&apos;s investment adviser public information website &ndash;
                www.adviserinfo.sec.gov or from RCA upon written request. RCA does not make any
                representations or warranties as to the accuracy, timeliness, suitability,
                completeness, or relevance of any information prepared by any unaffiliated third
                party, whether linked to RCA&apos;s web site or incorporated herein, and takes no
                responsibility therefor. All such information is provided solely for convenience
                purposes only and all users thereof should be guided accordingly. This website and
                information are provided for guidance and information purposes only. Investments
                involve risk and unless otherwise stated, are not guaranteed. Be sure to first
                consult with a qualified financial adviser and/or tax professional before
                implementing any strategy. This website and information are not intended to provide
                investment, tax, or legal advice.
              </>
            )}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
