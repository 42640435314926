import classnames from 'classnames';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ssoCreateEndpoint } from '../../constants/endpoints';
import useLoginModalStore from '../../stores/loginModalStore';
import useUserStore, { accountStatusEnum } from '../../stores/userStore';
import axiosInstance from '../../utils/axiosInstance';
import fetchLogout from '../../utils/fetch/logout';
import { Logo } from '../Icons';
import Loading from '../Loading';

const Header = ({ modifierClass, openAccount, login }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { user, logout, isUserLoggedIn } = useUserStore();
  const accountStatus = user.account_status;
  const { toggleModal } = useLoginModalStore();
  const isLoggedIn = isUserLoggedIn();
  const showLogin = login && !isLoggedIn;
  const navigate = useNavigate();

  const handleLogOut = async () => {
    setIsLoading(true);
    await fetchLogout();
    logout();
    setIsLoading(false);
    navigate('/');
  };

  const handleSSO = async () => {
    setIsLoading(true);
    //TODO Error handling
    let ssoUrl = '';
    const ssoResponse = await axiosInstance.get(ssoCreateEndpoint);
    if (ssoResponse?.data.url && ssoResponse?.data.url !== 'https://ThisIsATestUrl.None') {
      //window.location.href = ssoResponse.data.url;
      ssoUrl = ssoResponse.data.url;
    }
    setIsLoading(false);
    window.location.href = ssoUrl;
  };

  console.log('ac', accountStatus);

  return (
    <header
      className={classnames('header', {
        [`header--${modifierClass}`]: modifierClass,
        'header--without-border': !isLoggedIn && !openAccount,
      })}
    >
      <div className='h-wrapper'>
        <Link to='/' className={''}>
          <img src={'/assets/images/logo-black.svg'} />
          {/* <Logo className='header__logo' /> */}
        </Link>
        <nav className='header__buttons'>
          {openAccount && !isLoggedIn && (
            <Link
              to='/open-account'
              className={`header__button ${!showLogin ? '--no-border' : ''}`}
            >
              {openAccount}
            </Link>
          )}
          {showLogin && (
            <button className='header__button' type='button' onClick={toggleModal}>
              {login}
            </button>
          )}
          {isLoggedIn && user.username && (
            <div className='header__button dropdown'>
              {user.username}
              <div className='dropdown-content'>
                {accountStatus && accountStatus !== accountStatusEnum.ACCOUNT_OPEN && (
                  <Link className='dropdown__button' to='/open-account'>
                    Continue Application
                  </Link>
                )}
                {accountStatus === accountStatusEnum.ACCOUNT_OPEN && (
                  <button className='dropdown__button' onClick={handleSSO}>
                    Manage Account
                  </button>
                )}
                <button onClick={handleLogOut} className='dropdown__button'>
                  Log Out
                </button>
              </div>
            </div>
          )}
        </nav>
      </div>
      {isLoading && <Loading text='Loading...' />}
    </header>
  );
};

export default Header;
