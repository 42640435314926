import React from 'react';

const Warning = ({ className }) => {
  return (
    <svg className={className} viewBox='0 0 17 18' fill='none'>
      <path
        d='M8.5 1.6875C4.69193 1.6875 1.59375 4.96793 1.59375 9C1.59375 13.0321 4.69193 16.3125 8.5 16.3125C12.3081 16.3125 15.4062 13.0321 15.4062 9C15.4062 4.96793 12.3081 1.6875 8.5 1.6875ZM8.5 12.9343C8.36866 12.9343 8.24027 12.8931 8.13107 12.8158C8.02186 12.7386 7.93675 12.6288 7.88649 12.5003C7.83623 12.3718 7.82307 12.2304 7.8487 12.094C7.87432 11.9576 7.93757 11.8324 8.03044 11.734C8.12331 11.6357 8.24163 11.5687 8.37045 11.5416C8.49926 11.5145 8.63278 11.5284 8.75413 11.5816C8.87547 11.6348 8.97918 11.7249 9.05215 11.8406C9.12512 11.9562 9.16406 12.0921 9.16406 12.2312C9.16406 12.4177 9.0941 12.5965 8.96956 12.7284C8.84503 12.8603 8.67612 12.9343 8.5 12.9343ZM9.22117 5.86266L9.03059 10.1517C9.03059 10.3009 8.97462 10.444 8.87499 10.5495C8.77536 10.655 8.64023 10.7142 8.49934 10.7142C8.35844 10.7142 8.22331 10.655 8.12369 10.5495C8.02406 10.444 7.96809 10.3009 7.96809 10.1517L7.7775 5.86477V5.86301C7.77332 5.75997 7.78888 5.65711 7.82324 5.56061C7.8576 5.46411 7.91005 5.37598 7.97743 5.30151C8.04481 5.22704 8.12572 5.16778 8.2153 5.12729C8.30488 5.08681 8.40127 5.06593 8.49867 5.06593C8.59607 5.06593 8.69246 5.08681 8.78204 5.12729C8.87162 5.16778 8.95254 5.22704 9.01992 5.30151C9.0873 5.37598 9.13974 5.46411 9.1741 5.56061C9.20846 5.65711 9.22402 5.75997 9.21984 5.86301L9.22117 5.86266Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default Warning;
